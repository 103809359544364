<template>
  <table-container :list="list">
    <el-table-column type="index" label="序号"> </el-table-column>
    <el-table-column prop="agent_name" label="代理"> </el-table-column>
    <el-table-column prop="recharge_amount" label="充值金额"> </el-table-column>
    <el-table-column prop="usage_amount" label="消费金额"> </el-table-column>
    <!-- <el-table-column prop="withdraw" label="提现金额"> </el-table-column> -->
    <el-table-column prop="left_amount" label="当前余额"> </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
  },
  methods: {
    //充值
    handleRecharge() {},
    //提现
    handleWithdraw() {},
  },
};
</script>
<style lang="less" scoped>
</style>