<template>
  <el-row :gutter="20" class="row">
    <el-col :span="5">
      <el-select v-model="typyId" placeholder="选择店铺类型">
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col class="item" :span="4"> 总充值：￥{{ recharge_amount }} </el-col>
    <el-col class="item" :span="4"> 总消费：￥{{ usage_amount }} </el-col>
    <el-col class="item" :span="4"> 总余额：￥{{ left_amount }} </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Nav",
  props: {
    typeList: Array,
    recharge_amount: Number,
    usage_amount: Number,
    left_amount: Number,
  },
  data() {
    return {
      typyId: 1,
      shopType: [
        {
          id: 1,
          name: "自营",
        },
        {
          id: 2,
          name: "代理",
        },
        {
          id: 3,
          name: "阿里巴巴",
        },
      ],
    };
  },
  watch: {
    typyId(e) {
      this.$emit("typeChange", e);
    },
  },
};
</script>
<style lang="less" scoped>
.row {
  display: flex;
  align-items: center;
}
.item {
  font-size: 14px;
  color: #666;
}
</style>