<template>
  <div class="finance-container">
    <finance-nav
      :typeList="typeList"
      :recharge_amount="recharge_amount"
      :usage_amount="usage_amount"
      :left_amount="left_amount"
      @typeChange="handleTypeChange"
    ></finance-nav>
    <list :list="list"></list>
  </div>
</template>
<script>
import List from "./components/List";
import FinanceNav from "./components/Nav";
export default {
  name: "FinanceTotal",
  components: {
    FinanceNav,
    List,
  },
  data() {
    return {
      typeId: null,
      typeList: [],
      recharge_amount: null,
      usage_amount: null,
      left_amount: null,
      list: [],
    };
  },
  created() {
    this.getAgentTypeList();
    this.getTotalRechargesAndUsage();
  },
  methods: {
    //获取整体充值和资金使用情况
    async getTotalRechargesAndUsage() {
      const res = await this.$store.dispatch("getTotalRechargesAndUsage");
      this.recharge_amount = res.recharge_amount;
      this.usage_amount = res.usage_amount;
      this.left_amount = res.left_amount;
    },
    //获取代理类型下的代理列表
    async getInOutOrderStatistic() {
      const res = await this.$store.dispatch("getCategoryAgentsStatistic", {
        category_id: this.typeId,
      });
      this.list = res.agents;
    },
    //获取代理类型列表
    async getAgentTypeList() {
      const res = await this.$store.dispatch("getAgentTypeList");
      this.typeList = res.items;
      this.typeId = this.typeList[0].id;
      this.getInOutOrderStatistic();
    },
    //切换代理分类
    handleTypeChange(e) {
      this.typeId = e;
      this.getInOutOrderStatistic();
    },
  },
};
</script>
<style lang="less" scoped>
.finance-container {
  width: 100%;
}
</style>